import Vue from 'vue';

export default {
    find(parametros) {
        return Vue.axios.get("comida-guiso/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    get(id) {
        return Vue.axios.get("comida-guiso/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    update(parametros) {
        return Vue.axios.post("comida-guiso/update", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("comida-guiso/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("comida-guiso", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    duplicar(parametros) {
        return Vue.axios.post("comida-guiso/duplicado", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    descargarLayout(parametros) {
        return Vue.axios(
            {
                method: "GET",
                responseType: "blob",
                url: "comida-guiso/layout",
                params: parametros
            }
        )
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
    },
    guardarLayout(parametros){
        return Vue.axios.post("comida-guiso/importFile", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },

}